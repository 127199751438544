import Animations from './modules/Animations'
import Marquees from './modules/Marquees'

// Debug helper
const debug = false

if (!debug) {
  console.log = function () {}
}

export default class Main {
  constructor() {
    this.init()
  }

  init() {
    Animations.init()
    Marquees.init()
  }
}

const main = new Main()
