import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default class Animations {
  static init() {
    console.log('== Animations components ==')

    // Hero main load animation

    console.log('  -> Animate hero main')

    const scrollBar = document.querySelector('.scrolling-bar')
    const sectionHero = document.querySelector('.section--hero')
    const mainLogo = document.querySelector('#main-logo')
    const mainInfo = document.querySelector('#main-info')

    let tlHero = gsap.timeline()

    tlHero
      .from(scrollBar, {
        delay: 0.25,
        duration: 1,
        opacity: 0,
        ease: 'expo.out',
      })
      .from(sectionHero, {
        delay: -0.75,
        duration: 1,
        opacity: 0,
        ease: 'expo.out',
      })
      .from(mainLogo, {
        delay: -0.75,
        duration: 1,
        opacity: 0,
        y: 10,
        ease: 'expo.out',
      })
      .from(mainInfo, {
        delay: -0.75,
        duration: 1,
        opacity: 0,
        y: 10,
        ease: 'expo.out',
      })

    // Hero title scroll to

    const sectionIntro = document.querySelector('#section-intro')

    mainInfo.addEventListener('click', () => {
      sectionIntro.scrollIntoView({behavior: 'smooth'})
    })

    // Hero shapes floating animations

    console.log('  -> Animate hero shapes')

    animateShape('#symbol-hero-circle')
    animateShape('#symbol-hero-half-circle')
    animateShape('#symbol-hero-star')
    animateShape('#symbol-hero-four-point')

    // General animations

    console.log('  -> Animate general components')

    const animateBottom = document.querySelectorAll('.animate-bottom')

    animateBottom.forEach((item) => {
      let tl = gsap.timeline({paused: true})

      tl.from(item, {
        duration: 1,
        opacity: 0,
        y: 50,
        ease: 'expo.out',
      })

      ScrollTrigger.create({
        trigger: item,
        start: 'top 85%',
        end: 'bottom 90%',
        animation: tl,
        markers: false,
        toggleActions: 'play none none reverse',
      })
    })
  }
}

function getRandomValue(min, max) {
  return getRandomSign() * getRandomNumber(min, max)
}

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min
}

function getRandomSign() {
  return Math.random() < 0.5 ? -1 : 1
}

function animateShape(querySelectorString) {
  const shape = document.querySelector(querySelectorString)

  const tlShapeFloat = gsap.timeline({repeat: -1, yoyo: true, paused: true})

  const minDuration = 4
  const maxDuration = 10
  const minRotation = 3.75
  const maxRotation = 6
  const minTranslationX = 5.25
  const maxTranslationX = 9
  const minTranslationY = 5.25
  const maxTranslationY = 9
  const ease = 'sine.inOut'

  tlShapeFloat
    .to(shape, {
      duration: getRandomValue(minDuration, maxDuration),
      rotation: getRandomValue(minRotation, maxRotation),
      xPercent: getRandomValue(minTranslationX, maxTranslationX),
      yPercent: getRandomValue(minTranslationY, maxTranslationY),
      ease: ease,
    })
    .to(shape, {
      duration: getRandomValue(minDuration, maxDuration),
      rotation: getRandomValue(minRotation, maxRotation),
      xPercent: getRandomValue(minTranslationX, maxTranslationX),
      yPercent: getRandomValue(minTranslationY, maxTranslationY),
      ease: ease,
    })
    .to(shape, {
      duration: getRandomValue(minDuration, maxDuration),
      rotation: getRandomValue(minRotation, maxRotation),
      xPercent: getRandomValue(minTranslationX, maxTranslationX),
      yPercent: getRandomValue(minTranslationY, maxTranslationY),
      ease: ease,
    })
    .to(shape, {
      duration: getRandomValue(minDuration, maxDuration),
      rotation: getRandomValue(minRotation, maxRotation),
      xPercent: getRandomValue(minTranslationX, maxTranslationX),
      yPercent: getRandomValue(minTranslationY, maxTranslationY),
      ease: ease,
    })

  const tlShape = gsap.timeline({paused: true})

  tlShape.from(shape, {
    delay: getRandomNumber(1, 1.5),
    duration: getRandomNumber(2, 5),
    opacity: 0,
    scale: getRandomNumber(0.65, 0.9),
    ease: 'expo.out',
  })

  tlShape.play().then(tlShapeFloat.play())
}
